import React, { useState, useEffect, useContext } from "react";
import "../Facilities/Facilities.css";
import { Context } from "../../../utils/context";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
const Facilities = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home/all-facilities`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  return (
    <>
      <section className="facilities">
        <div className="container-fluid">
          <div className="row">
            <div className="heading-holder">
              <h1 className="book">Luxury</h1>
              <div className="overlay">
                <p className="rooms">VACATION AT EASE</p>
                <h1 className="hostel">Our Facilities</h1>
                <p className="sailing">
                  Bliss Hostel provides all services you need
                </p>
              </div>
            </div>
          </div>
          {data && data.length > 0 && (
            <Swiper
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
              autoplay={{ delay: 2000 }} // Set the autoplay delay in milliseconds (e.g., 3000 for 3 seconds)
            >
              {[...Array(Math.ceil(data.length / 8))].map((_, index) => (
                <SwiperSlide key={index}>
                  <div className="row icons_part">
                    {data.slice(index * 8, (index + 1) * 8).map((item) => (
                      <div
                        key={item.id}
                        className="col-md-3 col-6 col-sm-4 tea mt-2 mt-xxl-5 mt-xl-5 mt-md-5 mt-lg-5"
                      >
                        <div className="icon-wrapper">
                          <img
                            className="fix-editor"
                            src={IMG_URL + item.image}
                            style={{ borderRadius: "50%" }}
                            alt="Image"
                          />
                        </div>
                        <h4 className="breakfast mt-3">{item?.name}</h4>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </section>
    </>
  );
};

export default Facilities;
