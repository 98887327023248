import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:2525/";
  // const IMG_URL = "http://192.168.14.123:2525/";
  // const IMG_URL = "http://127.0.0.1:2525/";
  // const IMG_URL = "https://hostelnode.profcymabackend.com:2525/";
  // const IMG_URL = "https://newnode.hostelbliss.com:5555/";
  // const IMG_URL = "http://newnode.hostelbliss.com:5555";
  const IMG_URL = "https://mainnode.hostelbliss.com:2626/";

  // const navigate = useNavigate();

  // const [signin, setSignin] = useState(false);
  // // const [language, setLanguage] = useState(1);
  // const [usertype, setUsertype] = useState("");
  // const [userdata, setUserData] = useState({});
  // const [token, setToken] = useState("");
  // const [isAllow, setisAllow] = useState([]);

  // const minLength = 2; // Set your desired minimum length
  // const maxLength = 30; // Set your desired maximum length

  // useEffect(() => {
  //   isTokenValid();
  // }, [signin]);

  // const getuserData = async (id) => {
  //   const response = await getData(`/login/${id}`, { id: id });
  //   await setUserData(response?.data);
  // };

  // const [isSidebarOpen, setSidebarOpen] = useState(true);
  // const toggleSidebar = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };

  // const isTokenValid = async () => {
  //   const token = Cookies.get("it_pulse_security");

  //   if (token) {
  //     // Decode the token to get the expiration time
  //     const decodedToken = JSON.parse(atob(token.split(".")[1]));
  //     const currentTime = Date.now() / 1000;

  //     // Check if the token has expired
  //     if (decodedToken.exp < currentTime) {
  //       Cookies.remove("it_pulse_security", { path: "/" });
  //       setSignin(false);
  //       setUsertype("");
  //       setisAllow([]);
  //       navigate("/");
  //     } else {
  //       setisAllow(decodedToken?.permissions);
  //       getuserData(decodedToken.user);
  //       setUsertype(decodedToken.name);
  //       setSignin(true);
  //     }
  //   } else {
  //     setisAllow([]);
  //     setSignin(false);
  //     setUsertype("");
  //     navigate("/");
  //   }
  // };

  // const ErrorNotify = (name) => {
  //   toast.error(`${name} deleted successfully.`, {
  //     position: "top-right",
  //     autoClose: 100,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { background: "red", color: "white" },
  //   });
  // };

  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        // getEditData,
        // editData,
        editStatusData,
        deleteData,
        // minLength,
        // maxLength,
        // signin,
        // setSignin,
        // usertype,
        // setUsertype,
        // userdata,
        // setUserData,
        getDownloadDataExcel,
        // ErrorNotify,
        // setisAllow,
        // isAllow,

        // token,
        // setToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
