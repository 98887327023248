import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import RoomsBanner from "./RoomsBanner/RoomsBanner";
import RoomsCard from "./RoomsCard/RoomsCard"
const Rooms = () => {

  const topRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCapacity = queryParams.get("capacity");

  useEffect(() => {

    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div ref={topRef}>
      <RoomsBanner />
      <RoomsCard selectedCapacity={selectedCapacity} />
    </div>
  );
};
export default Rooms;
