/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState, useContext } from "react";
import "./BlogDetailsBanner.css";


const BlogDetailsBanner = () => {
  

  return (
    <>
      <section className="main">
        {/* rooms-banner section start */}
        <div className="banner" >

          <div className="bg-img">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/Images/Rooms/Banner/Rooms_banner_img.png"
              }
              className="banner-img"
              alt="..."
            />
          </div>
          <div className="text-holder">
            <h1>Blog</h1>
          </div>
          <div className="overley"></div>
        </div>
        {/* rooms-banner section end */}
      </section>
    </>
  );
};
export default BlogDetailsBanner;
