import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./Contact.css";
import { Context } from ".././../utils/context";
import { Button, Modal } from "react-bootstrap";
import Footer from "../Footer/Footer";
import LazyLoad from "react-lazyload";
import Verified from "../AboutUs/animation/json/Verified";
export const Contact = () => {
  const topRef = useRef(null);
  const location = useLocation();

  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    massage: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    name: "",
    email: "",
    massage: "",
  });

  const [modalShow, setModalShow] = React.useState(false);

  const handleShow = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location = "/";
      setModalShow(false);
    }, 8000);
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {};

    // Perform validation checks and update validation messages
    if (!formData.name) {
      newValidationMessages.name = "Name is required";
      isValid = false;
    }

    // Validate email
    if (!formData.email) {
      newValidationMessages.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newValidationMessages.email = "Invalid email format";
      isValid = false;
    }

    if (!formData.massage) {
      newValidationMessages.massage = "Message is required";
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // If validation fails, return early
      return;
    }

    try {
      const response = await postData("/without-login/contact", formData);

      if (response?.success) {
        setModalShow({ code: response.code, message: response.message });
        setTimeout(() => {
          window.location = "/";
          setModalShow(false);
        }, 3000);
      } else {
        console.error("Submission failed:", response?.error);
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error
    }
  };

  const scrollIntoView = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Check if the location pathname is '/contact' and scroll into view
  if (location.pathname === "/contact") {
    scrollIntoView();
  }

  return (
    <>
      <section className="contact" ref={topRef}>
        <div className="vdo-section">
          <LazyLoad>
            <video
              src={process.env.PUBLIC_URL + "/assets/video/Contact/contact.mp4"}
              muted
              loop
              autoPlay // Add autoPlay attribute
              className=" video-element"
            />
          </LazyLoad>

          <div className="overlay-form ">
            <div className="middle-content">
              <h3>Get in Touch</h3>
              <form>
                <div className=" ">
                  <label for="Name" classname="form-label"></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name*"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  <p className="text-danger">{validationMessages.name}</p>
                </div>
                <div className=" ">
                  <label htmlFor="inputEmail4" className="form-label"></label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your E-mail*"
                    id="email"
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  <p className="text-danger">{validationMessages.email}</p>
                </div>
                <div className=" ">
                  <label for="message" classname="form-label"></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your message*"
                    onChange={(e) =>
                      setFormData({ ...formData, massage: e.target.value })
                    }
                  />
                  <p className="text-danger">{validationMessages.massage}</p>
                </div>
              </form>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4">
                <div className="submit">
                  <button
                    type="button"
                    className="btn btn-submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <div className="contact-modal">
                    <Modal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      centered
                      className="Home-PopUp"
                    >
                      <Modal.Body>
                        <Verified />
                        <h1 className="successfully">
                          Thank You For Your Contact.{" "}
                        </h1>
                        <h1 className="Thanks">Thank You!</h1>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-footer mt-3">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                  <div className="info">
                    <h6>Address</h6>
                    <div className="dashed"></div>
                    <p>123 Street Name, City , 32008</p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                  <div className="info">
                    <h6>Phone Number</h6>
                    <div className="dashed"></div>
                    <p>+123 0456 789</p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12  ">
                  <div className="info">
                    <h6>Email Address</h6>
                    <div className="dashed"></div>
                    <p>info@yourlinks.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-form-two ">
          <div className="middle-content">
            <h3>Get in touch</h3>
            <form>
              <div className=" ">
                <label for="Name" classname="form-label"></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name*"
                />
              </div>
              <div className=" ">
                <label htmlFor="inputEmail4" className="form-label"></label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your Email*"
                  id="inputEmail4"
                />
              </div>
              <div className=" ">
                <label for="message" classname="form-label"></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your message*"
                />
              </div>
            </form>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4">
              <div className="submit">
                <button type="button" class="btn btn-submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="form-footer px-5 mt-5">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="info">
                  <h6>Address</h6>
                  <div className="dashed"></div>
                  <p>123 Street Name, City , 32008</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="info">
                  <h6>Phone Number</h6>
                  <div className="dashed"></div>
                  <p>+123 0456 789</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12  ">
                <div className="info">
                  <h6>Email Address</h6>
                  <div className="dashed"></div>
                  <p>info@yourlinks.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer showFooter={showFooter ? true : false} />
      <footer className="footer">...</footer>
  ) : null; */}
    </>
  );
};
export default Contact;
