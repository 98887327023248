import React from 'react'
import Lottie from "react-lottie";
import Play_json from "../../animation/Play.json"
 import './Play.css'
const Play = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Play_json,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <>
     <section className='play'>
        <div className='play-lottie '>
          <Lottie 
            options={defaultOptions} 
             />
        </div>
      </section>
    </>
  )
}

export default Play