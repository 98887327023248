import React from 'react'
import { Container } from 'react-bootstrap'
import './DownloadBrochure.css'

const DownloadBrochure = () => {

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + "/assets/pdf/Bifold Brochure_Updated_final_rates.pdf"; // Path to your PDF file
        link.download = 'Brochure.pdf'; // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <section className='download-brochure'>
            <img
                onClick={handleDownload}
                src={process.env.PUBLIC_URL + "/assets/Images/HomePage/Bannerdownload.jpg"}
                className="sub-banner-img" alt="..."
            />
        </section>
    )
}

export default DownloadBrochure