import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Context } from "../../../utils/context";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import '../AboutUsBanner/AboutUsBanner.css'
const AboutUsBanner = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home/all-banner`);
      setData(response?.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);


  return (
    <>
      {/* <section className="About-us">

        <div className="banner">
          <div className="text-holder">
            <h1>About us</h1>
          </div>
        </div>

      </section> */}

      <section className="main">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
          }}
          showPagination={false}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {data &&
            data
              .filter((slide) => slide.banner_type === "About Us")
              .map((slide, index) => (
                <SwiperSlide>
                  <div key={index} className="banner">

                    <div className="bg-img">
                      {slide.mediaType === "video" ? (
                        <video
                          autoPlay
                          muted
                          loop
                          className="banner-img"
                        // style={{ width: "100%", height: "800px" }}
                        >
                          <source src={IMG_URL + slide.image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={IMG_URL + slide.image}
                          className="banner-img"
                          alt="slider-img"
                        // style={{ width: "100%", height: "800px" }}
                        />
                      )}
                    </div>
                    <div className="text-holder">
                      <h1>About us</h1>
                    </div>

                    <div className="overley"></div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </section>

    </>
  )
}

export default AboutUsBanner;
