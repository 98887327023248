import React, { useState, useEffect, useContext } from "react";
import "./Traveller.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";

export const Traveller = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  var HtmlToReactParser = require("html-to-react").Parser;

  var htmlToReactParser = new HtmlToReactParser();
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/blog/all-blog`);
      setData(response?.data);
    } catch (error) {}
  };

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getSocialMedia();
  }, []);

  console.log(data);

  const [length, setLength] = useState(3);

  return (
    <section className="blog-page ">
      <div className="container-fluid">
        <div className=" complete-sec ">
          {data?.map((val1, index) =>
            length > index && index % 2 == 0 ? (
              <div className="row blog-one">
                {val1?.map((val, index) => (
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                    <div
                      className={`row ${index % 2 == 0 ? "blog1" : "blog2"}`}
                    >
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                        <div className=" content-one content">
                          <div className="all-circles">
                            <div className="circles">
                              <div className="circle"></div>
                              <div className="circle"></div>
                              <div className="circle"></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                            </div>
                            <div className="circles">
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                            </div>
                            <div className="circles">
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                            </div>
                            <div className="circles">
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                              <div className="circle "></div>
                            </div>
                          </div>

                          <div className="heading">
                            <p>{val?.name}</p>
                          </div>
                          <div className="text">
                            <div className="description-div">
                              <p>{htmlToReactParser.parse(val?.description)}</p>
                            </div>
                          </div>
                          <div className="custum-butn mt-5 mt-sm-3 mt-md-5 mt-lg-4 mt-xl-3 mt-xxl-3">
                            <Link to={`/blogAllDetails/${val?.id}`}>
                              <button
                                type="button"
                                className="btn butn btn-secondary"
                              >
                                Read more
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                        <div className="square-img ">
                          <img
                            src={IMG_URL + val?.image1}
                            className="image"
                            alt="Image 2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                  <div className="row  blog2">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0">
                      <div className="content-two content">
                        <div className="all-circles">
                          <div className="circles">
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                        </div>
                        <div className="heading">
                          <p>Unknown Spots to Relax in New Lenox</p>
                        </div>
                        <div className="text">
                          <p>
                            Quuntur magni dolores eos qrationevolup ta sequi
                            nesciunt. Neque porro quisquam est, sit amet,
                            consectetur adipisicing elit, sed do eius tempor exer
                            ullamco laboris nisi…
                          </p>
                        </div>
                        <div className="custum-butn mt-5 mt-sm-3 mt-md-3 mt-mt-lg-4 mt-xl-3 mt-xxl-3 ">
                          <button type="button" class="btn butn btn-secondary ">
                            Read more
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0">
                      <div className="square-img">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/BlogPage/img-2.png"
                          }
                          className="image"
                          alt="Image 2"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            ) : (
              length > index && (
                <div className="row blog-two">
                  {val1?.map((val, index) => (
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <div
                        className={`row ${index % 2 == 0 ? "blog3" : "blog4"}`}
                      >
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                          <div className="square-img ">
                            <img
                              src={IMG_URL + val?.image1}
                              className="image"
                              alt="Image 2"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0 ">
                          <div className=" content-one content">
                            <div className="all-circles">
                              <div className="circles">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                              </div>
                              <div className="circles">
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                              </div>
                              <div className="circles">
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                              </div>
                              <div className="circles">
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                                <div className="circle "></div>
                              </div>
                            </div>

                            <div className="heading">
                              <p>{val?.name}</p>
                            </div>
                            <div className="text">
                              <div className="description-div">
                                <p>
                                  {htmlToReactParser.parse(val?.description)}
                                </p>
                              </div>
                            </div>
                            <div className="custum-butn mt-5 mt-sm-3 mt-md-5 mt-lg-4 mt-xl-3 mt-xxl-3">
                              <Link to={`/blogAllDetails/${val?.id}`}>
                                <button
                                  type="button"
                                  className="btn butn btn-secondary"
                                >
                                  Read more
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            )
          )}

          <div className="middle-butn">
            {data?.length > 3 && (
              <button
                type="button"
                class="btn butn-two btn-secondary "
                onClick={() => {
                  data?.length == length
                    ? setLength(3)
                    : setLength(data?.length);
                }}
              >
                <div className="p-0 m-0">
                  <i class="arrow down"></i>
                </div>
                {data?.length == length ? "View Less" : "Load More"}
              </button>
            )}
          </div>
          {/* main contain-section end */}

          {/* icons section start */}
          <div className=" blog-icons ">
            <div className=" icon-text ">
              <p>Follow Us</p>
            </div>
            <div className="line "></div>
            {socialMedia?.map((val, index) => (
              <div key={index}>
                <a href={val?.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={IMG_URL + val?.image}
                    className="img-fluid mt-2"
                    alt="..."
                    style={{ width: "35px", height: "35px" }} // Example inline styles for width and height
                  />
                </a>
              </div>
            ))}
            {/* <div className="insta">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Images/BlogPage/insta.png"
                }
                className="image"
                alt="Image 2"
              />
            </div>
            <div className="twitter">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Images/BlogPage/twitter.png"
                }
                className="image"
                alt="Image 2"
              />
            </div>
            <div className="linkedin">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Images/BlogPage/linkedin.png"
                }
                className="image"
                alt="Image 2"
              />
            </div> */}
          </div>
          {/* icons section end */}
        </div>
      </div>
    </section>
  );
};
export default Traveller;
