import React, { useEffect, useRef, useState, useContext } from "react";
import "../LuxurySection/LuxurySection.css";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
const LuxurySection = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/luxury-section`);

      setData(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="Luxury_Section">
        <div className="container-fluid">
          {data?.map((val) => (
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="images-hover">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/banner/background-2.png"
                    }
                    className="background mt-2"
                    alt="..."
                  />
                  <div className="img-wrapper">
                    <img className="inner-img1" src={IMG_URL + val?.image1} />
                  </div>
                  <div className="img-wrapper1">
                    <img className="inner-img2" src={IMG_URL + val?.image2} />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-md-12 mb-3 mt-5">
                    <div className="heading-holder">
                      <h1 className="about">About</h1>
                      <div className="overlay">
                        <p className="services">{val?.name}</p>
                        <h1 className="luxury">{val?.subname} </h1>
                        {/* <h1 className="luxury">hospitality experience.</h1> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h4
                    className="sitting"
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 5,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {parse(val?.description || "")}
                  </h4>
                </div>
                <div className="row justify-content-center">
                  <NavLink to="/aboutus">
                    <div className="about-btn">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default LuxurySection;
