/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState, useContext } from "react";
import "./RoomsDetailsSlider.css";
import { Context } from "../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
const RoomsDetailsSlider = ({ roomDetails }) => {
  const { IMG_URL, getData } = useContext(Context);
  const [data, setData,] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getData(`/without-login/room/all-room-images/${roomDetails.id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataForRoom = async () => {
      await fetchData();

    };

    fetchDataForRoom();
  }, [roomDetails.id]);


  return (
    <>
      <section className="RoomsDetailsSlider">
        {/* RoomsDetailsSlider end */}
        <div className="main-slider">
          <div className="row me-0 ms-0">
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              showPagination={true}
              navigation={false}
              loop={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
              }}
            >

              {data &&
                data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-block">
                      <img
                        src={IMG_URL + item.image}
                        className="slider-img"
                        alt="..."
                      />
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className="back-next-btn">
            <Link to="/Rooms">
              <button type="button" class="btn btn-primary">
                BACK
              </button>
            </Link>
            <Link to={`/hostel_form/${roomDetails.id}`}>
              <button type="button" className="btn btn-primary">
                BOOK THIS ROOM
              </button>
            </Link>
          </div>
          {/* RoomsDetailsSlider end */}
        </div>
      </section>
    </>
  );
};
export default RoomsDetailsSlider;

