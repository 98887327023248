import React, { useEffect, useRef, useState, useContext } from "react";
import "../PrivacyPolicyTabs/PrivacyPolicyTabs.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";

const PrivacyPolicyTabs = () => {

  var HtmlToReactParser = require('html-to-react').Parser;

  var htmlToReactParser = new HtmlToReactParser();

  const [sectionToShow, setSectionToShow] = useState(" ");
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/privacy-policy`);

      setData(response?.data);

      // Set the first section as the default section to show
      setSectionToShow(response?.data[0]?.name || "");


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, []);

  // const sections = data.map((section) => section.name);

  return (
    <>
      <section className="main">
        <div className="container">
          {/* PrivacyPolicyTabs section start */}
          <div className="PrivacyPolicyTabs">
            <div className="row justify-content-center mx-auto">
              <div className="col-xxl-10 col-xl-10 col-lg-10 col-sm-10 col-md-10 col-10">
                {/* Introduction content start */}
                {data?.map((section) => (
                  <div
                    key={section.name}
                    className="Introduction"
                    style={{
                      display:
                        sectionToShow === section?.name ? "block" : "none",
                    }}
                  >
                    <div className="text-holder">
                      <div className="head-sec">
                        <div className="all-circles">
                          <div className="circles">
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                          <div className="circles">
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                            <div className="circle "></div>
                          </div>
                        </div>
                        {/* <Link to="/PrivacyPolicy"> */}
                        <div>
                          <button
                            type="button"
                            className="butn privacybackBtn"
                            onClick={() => setSectionToShow(data[0]?.name)}
                            style={{ display: sectionToShow === data[0]?.name ? 'none' : 'block' }}
                          >
                            Back
                          </button>

                        </div>
                        {/* </Link> */}
                      </div>
                      <div className="heading">
                        <h6>WELCOME TO BLISS HOSTEL</h6>
                        <h1>{section?.name}</h1>
                        <div className="description-div">
                          <p>
                            {htmlToReactParser.parse(section?.description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}

                {/* button section */}

                <div className="tabs mt-5">
                  <div className="row justify-content-center mx-auto">
                    <div className="name-tabs  col-xxl-12 col-xl-12 col-12  ">

                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {data?.map((section, index) => (
                          <li
                            key={section.name}
                            className="nav-item col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-12 mt-2 "
                            role="presentation"
                          >
                            <button
                              className={`btn btn-block ${sectionToShow === section?.name ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              data-bs-target="#home-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="home-tab-pane"
                              aria-selected={sectionToShow === section?.name}
                              onClick={() => {
                                setSectionToShow(section?.name);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              <p>{section?.name}</p>
                            </button>
                          </li>
                        ))}

                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PrivacyPolicyTabs section end */}
      </section>
    </>
  );
};
export default PrivacyPolicyTabs;










