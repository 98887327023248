import React, { useEffect, useRef, useState, useContext } from "react";
import "../HomePageBanner/HomePageBanner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useLocation } from "react-router-dom";
// import videoimg from "../HomePageBanner/videos/hostel.mp4";
// import video from "../HomePageBanner/videos/hostelbanner.mp4";
// import video_mobile from "../HomePageBanner/videos/mobile.mp4";
// import big_screen from "../HomePageBanner/videos/bigscreen.mp4";
import date_img from "../../icon/calendar.png";

// import whovideo from "../BannerSlider/videos/square.mp4"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import LazyLoad from "react-lazyload";
import { Context } from "../../../utils/context";
import { NavLink } from "react-router-dom";

const HomePageBanner = () => {
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [room, setRoom] = useState([]);
  const vidRef = useRef();
  const getDataAll = async () => {
    const response = await getData(`/without-login/home/all-banner`);
    await setData(response?.data);
  };

  const getRoomData = async () => {
    try {
      const response = await getData(`/without-login/room/all-room`);
      setRoom(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedCapacity, setSelectedCapacity] = useState("");

  const handleCapacityChange = (capacity) => {
    setSelectedCapacity(capacity);
    // You can perform additional logic here based on the selected capacity
  };

  const isDateDisabled = (date) => {
    // Disable dates before the startDate
    return date > startDate;
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     vidRef.current?.play();
  //     await getDataAll();
  //   };
  //   fetchData();
  // }, [0]);

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getRoomData();
    getSocialMedia();
  }, []);
  // const [selectedDate, setSelectedDate] = useState(null);

  // const handleChange = (date) => {
  //   setSelectedDate(date);
  // };s

  return (
    <>
      <section className="Hostel_HomePage_Banner p-0">
        <div className="container-fluid p-0">
          <div className="row me-0 ms-0">
            <div className="hostel-main p-0">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                pagination={{
                  clickable: false,
                }}
                showPagination={false}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {data &&
                  data
                    ?.filter((slide) => slide.banner_type === "Home")
                    .map((slide, index) => (
                      <SwiperSlide>
                        <div className="hostel_banner_slider p-0">
                          <div className="vedio">
                            {slide.mediaType === "video" ? (
                              <video
                                autoPlay
                                muted
                                loop
                                className="video-class-grid"
                                // style={{ height: "650px", width: "100%" }}
                              >
                                <source
                                  src={IMG_URL + slide.image}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={IMG_URL + slide.image}
                                className="video-class-grid"
                                alt="slider-img"
                                // style={{ height: "650px", width: "1580px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3 mt-5">
                            <div className="heading-holder">
                              <h2 className="book">{slide.titleblur}</h2>
                              <div className="overlay">
                                <p className="room">{slide.subtitle}</p>
                                <h1 className="hostel">{slide.name}</h1>
                                <h1 className="hostel">{slide.title2}</h1>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="room-btn">
                                <Link to="/aboutus">
                                  <button type="button" class="btn btn-primary">
                                    Explore
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="overley"></div>
                      </SwiperSlide>
                    ))}
                {/* {data &&
                  data?.map((slide, index) => (
                    <SwiperSlide>
                      <div className="hostel_banner_slider p-0">
                        <div className="vedio">
                          {slide.mediaType === 'video' ? (
                            <video
                              muted
                              loop
                              className="video-class-grid"
                            >
                              <source src={IMG_URL + slide.video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={IMG_URL + slide.image}
                              className="video-class-grid"
                              alt="slider-img"
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-3 mt-5">
                          <div className="heading-holder">
                            <h1 className="book">Book Early</h1>
                            <div className="overlay">
                              <p className="rooms">Our Favourite Rooms</p>
                              <h1 className="hostel">Hostel</h1>
                              <h1 className="hostel">Master's Rooms</h1>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="room-btn">
                              <button type="button" class="btn btn-primary">
                                ROOM SUITES
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                  ))} */}
              </Swiper>
            </div>
          </div>

          {/* Book Your Stay started */}

          {/* <div className="book_your_stay me-0 ms-0">
            <div className="container">
              <div className="book_stay">
                <div class="g-slide"></div>
                <div className="book">
                  <div className="row  me-0 ms-0 book-main">
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 p-0">
                      <h4 className="book1 mt-2 mt-lg-4 mt-xxl-4">
                        Book your stay!
                      </h4>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-3 p-0">
                      <div className="input-group mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0 ">
                        <div className="check_in_out">
                          {" "}
                          <b>Check In</b>
                        </div>

                        <div className="DatePick">
                          <span className="input-group-text" id="basic-addon1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/banner/calender.png"
                              }
                              className="calender"
                              alt="..."
                            />
                          </span>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="MMMM dd, yyyy"
                            minDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-3 p-0">
                      <div className="input-group mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                        <div className="check_in_out">
                          {" "}
                          <b>Check Out</b>
                        </div>
                        <div className="DatePick">
                          <span className="input-group-text" id="basic-addon1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/banner/calender.png"
                              }
                              className="calender"
                              alt="..."
                            />
                          </span>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="MMMM dd, yyyy"
                            filterDate={isDateDisabled}
                            minDate={startDate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-3 p-0">
                      {room?.length > 0 && (
                        <div className="adult_select  mt-2 mt-sm-2 mt-md-0 mt-lg-0 me-xxl-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) =>
                              handleCapacityChange(e.target.value)
                            }
                          >
                            <option value="">Select Capacity</option>
                            {[...new Set(room.map((val) => val.capacity))].map(
                              (capacity) => (
                                <option key={capacity} value={capacity}>
                                  {capacity}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                    </div>

                    <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-3 p-0">
                      <NavLink to={`/Rooms?capacity=${selectedCapacity}`}>
                        <div class="check mt-2 mt-sm-2 mt-md-3 mt-lg-2 mt-xl-0 mt-xxl-0">
                          <button
                            type="button"
                            href="/Rooms"
                            class="btn btn-primary"
                          >
                            CHECK AVAILABILITY
                          </button>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="date-picker-container"></div>
        </div>
      </section>
      <div className="icons">
        {socialMedia?.map((val, index) => (
          <div key={index}>
            <a href={val?.link} target="_blank" rel="noopener noreferrer">
              <img
                src={IMG_URL + val?.image}
                className="img-fluid mt-2"
                alt="..."
              />
            </a>
          </div>
        ))}

        <div>
          <div class="vl"></div>
        </div>
        <div className="follow">
          <p className="us">Follow us</p>
        </div>
      </div>
    </>
  );
};

export default HomePageBanner;
