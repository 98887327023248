import React, { useEffect, useRef, useState, useContext } from "react";

import "./BlogDetails.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const BlogDetails = () => {
  const { getData, IMG_URL } = useContext(Context);
  const { id } = useParams();
  const [data, setdata] = useState({});

  const fetchData = async () => {
    try {
      if (id) {
        const response = await getData(`/without-login/blog/all-blog/${id}`);
        setdata(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatDate(dateTimeString) {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString(undefined, options);
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
    getSocialMedia();
  }, []);

  return (
    <>
      {/* banner-section start */}
      {/* <section className="blog-details">
        <div className="container-fluid p-0">
          <div className="banner">
            <div className="text-holder">
              <h1>Blog</h1>
            </div>
          </div>
        </div>
      </section> */}
      {/* banner-section end */}

      {/* details-section start */}
      <section className="details ">
        <div className=" ">
          <div className="complete-sec">
            <div className="head-sec">
              <div className="all-circles">
                <div className="circles">
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                </div>
                <div className="circles">
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                </div>
                <div className="circles">
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                </div>
                <div className="circles">
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                  <div className="circle "></div>
                </div>
              </div>

              <Link to="/blog">
                <button type="button" className="butn">
                  Back
                </button>
              </Link>
            </div>
            <div className="heading">
              <h4>{data?.name}</h4>
            </div>
            <div className="text-icons ">
              <div className="dates d-flex">
                <div className="space">
                  <p>{formatDate(data?.createdAt)}</p>
                </div>
                {/* <div className="space d-flex">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/BlogDetails/pencil-icon.png"
                    }
                    className="icon"
                    alt="..."
                  />
                  <p>CINDY JEFFERSON</p>
                </div> */}
              </div>
              {/* <div className="two-icons d-flex">
                <div className="space">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/BlogDetails/msg-icon.png"
                    }
                    className="icon"
                    alt="..."
                  />{" "}
                  2
                </div>
                <div className="space">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/BlogDetails/heart-icon.png"
                    }
                    className="icon"
                    alt="..."
                  />{" "}
                  26
                </div>
              </div> */}
            </div>
            <div className="image-one">
              <img
                src={IMG_URL + data?.image2}
                className="main-img"
                alt="..."
              />
            </div>
            <div className="text ">
              <p>{parse(data?.description || "")}</p>
            </div>
          </div>
        </div>
      </section>
      {/* details-section end */}

      {/* footer section start */}
      <section className="footer-sec">
        <div className=" blog-icons ">
          <div className=" icon-text ">
            <p>Follow Us</p>
          </div>
          <div className="line "></div>
          {socialMedia?.map((val, index) => (
            <div key={index}>
              <a href={val?.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={IMG_URL + val?.image}
                  className="image"
                  alt="..."
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
            </div>
          ))}

          <div className="insta">
            <img
              src={process.env.PUBLIC_URL + "/assets/Images/BlogPage/insta.png"}
              className="image"
              alt="Image 2"
            />
          </div>
          <div className="twitter">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/Images/BlogPage/twitter.png"
              }
              className="image"
              alt="Image 2"
            />
          </div>
          <div className="linkedin">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/Images/BlogPage/linkedin.png"
              }
              className="image"
              alt="Image 2"
            />
          </div>
        </div>
      </section>
      {/* footer section end */}
    </>
  );
};
export default BlogDetails;
