/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState, useContext } from "react";
import "./RoomsDetailsBanner.css";
import { Context } from "../../../utils/context";
const RoomsDetailsBanner = ({ roomDetails }) => {

  const { IMG_URL } = useContext(Context);


  return (
    <>
      <section className="main">
        {/* rooms-details-banner section start */}
        <div className="banner">
          <div className="bg-img">
            <img
              src={
                IMG_URL + roomDetails.image
              }
              className="banner-img"
              alt=""
            />
          </div>
          <div className="text-holder">
            {/* <h1>Rooms</h1> */}
          </div>
          <div className="overley"></div>
        </div>
        {/* rooms-details-banner section end */}
      </section>
    </>
  );
};
export default RoomsDetailsBanner;
