import React, { useEffect, useRef, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Context } from "../../../utils/context";
import "./Team.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

const Team = () => {

  const [activeIndex, setActiveIndex] = useState();

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/about-us/all-team`);

      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, []);

  return (
    <>
      {/* Team Section Started */}

      <section className="Team">
        <div className="content">
          <p className="our text-center">OUR TEAM</p>
          <h1 className="meet text-center">Meet The Team</h1>
        </div>
        <div className="container">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            navigation
            initialSlide={1}// Set initialSlide to 1 for the second slide to be active by default
            centeredSlides={true}
            // onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            breakpoints={{
              280: { slidesPerView: 1 },
              576: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              991: { slidesPerView: 2 },
              1200: { slidesPerView: 3 },
              2600: { slidesPerView: 3 },
            }}
          >
            {data?.map((index) => (
              <SwiperSlide key={index.id}>
                <div className="row team-member justify-content-center">
                  <div className="">
                    <div className="main">
                      <img
                        src={IMG_URL + (index?.image || "")}
                        className="yash"
                        alt="..."
                      />
                    </div>
                    <h5 className="sean text-center mt-2">{index?.name}</h5>
                    <h6 className="head text-center">{index?.position}</h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Team;
