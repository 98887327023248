import React from 'react'
import '../Hooks/Hooks.css'
const Hooks = () => {
  return (
    <>
    <div class="perspective-text">
</div>
    </>
  )
}

export default Hooks
