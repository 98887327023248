import React, { useState, useEffect, useContext } from "react";
import "../RoomsCard/RoomsCard.css";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

library.add(fas);
const RoomsCard = ({ selectedCapacity }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/room/all-room`);
      setData(response?.data);
      console.log("selected :- ", selectedCapacity);
      console.log(typeof selectedCapacity);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateStars = (rating) => {
    const starCount = Math.floor(rating); // Assuming rating is out of 5
    const stars = [];

    for (let i = 0; i < starCount; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon="fa-solid fa-star"
          className="yellow-star"
        />
      );
    }

    return stars;
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [selectedCapacity]);

  return (
    <>
      <section className="main">
        {/*RoomsCard section start */}
        <div className="Rooms-Card">
          <div className="text-holder">
            <h1>Rooms</h1>
          </div>

          {/* *************************Card Body*********************************** */}

          {data && data.length > 0 && (
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              {[...Array(Math.ceil(data.length / 8))].map((_, index) => (
                <SwiperSlide key={index}>
                  <div className="row">
                    {data.slice(index * 8, (index + 1) * 8).map((item) => {
                      // Filter rooms based on selected capacity
                      if (
                        selectedCapacity === null ||
                        (item && item.capacity === parseInt(selectedCapacity))
                      ) {
                        return (
                          <div
                            key={item.id}
                            className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4  "
                          >
                            <div className="card">
                              <img
                                src={IMG_URL + item?.image}
                                className="rooms-images"
                                alt="..."
                              />
                              <div className="card-body">
                                <div className="card-title">
                                  <Link
                                    to={`/roomsAllDetails/${item.id}`}
                                    className="card-link"
                                  >
                                    <div className="text-holder">
                                      <h5>{item?.name}</h5>
                                      <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                  </Link>
                                </div>
                                <div className=" card-text">
                                  <div className="row me-0 ms-0">
                                    <div className="icon-text-holder col-md-5 col-6">
                                      {/* <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Images/Rooms/RoomsCards/sq_ft.png"
                                        }
                                        className="icon"
                                        alt="..."
                                      /> */}
                                      {/* <span>{item?.size} sq_ft</span> */}
                                    </div>
                                    <div className=" icon-text-holder col-md-6 col-6">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Images/Rooms/RoomsCards/twin_bed.png"
                                        }
                                        className="icon"
                                        alt="..."
                                      />
                                      <span>{item?.bed?.name}</span>
                                    </div>
                                    {/* <div className=" icon-text-holder col-md-12 col-12">
                                      <FontAwesomeIcon icon="fa-solid fa-user" />
                                      <span>Upto {item?.capacity} Guest</span>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="stars">
                                  <FontAwesomeIcon icon="fa-solid fa-asterisk" />
                                  <span>₹ {item?.price} / Per Month</span>
                                  {generateStars(item?.rating)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        {/* RoomsCard section end */}
      </section>
    </>
  );
};
export default RoomsCard;
