import React, { useEffect, useRef, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "../OtherSay/OtherSay.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";

const OtherSay = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home/other-say`);

      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="Other_Say">
        <div className="container-fluid p-0">
          <div className="heading-holder">
            <h1 className="book">Luxury</h1>
            <div className="overlay">
              <p className="rooms">PEOPLE LOVE OUR HOSTEL</p>
              <h1 className="hostel">What Others Say</h1>
            </div>
          </div>

          <div className="row me-0 ms-0">
            <div className="slider_main">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                pagination={{
                  clickable: true,
                }}
                showPagination={false}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {data?.map((index) => (
                  <SwiperSlide>
                    <div class="device">
                      <div className="image">
                        <img
                          src={IMG_URL + index?.image}
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <h4 className="text">
                        <div className="quatation">
                          {parse(index?.description)}
                        </div>
                      </h4>
                      <h5 className="shubham">{index?.name}</h5>
                    </div>
                  </SwiperSlide>
                ))}
                {/* <SwiperSlide>
                  <div class="device">
                    <div className="image">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/men.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <h4 className="text">

                      <div >
                        Lorem Ipsum is simply dummy Lorem
                      </div>



                    </h4>
                    <h5 className="shubham">Shubham Yadav</h5>
                  </div>

                </SwiperSlide> */}

                {/* <SwiperSlide>
                <div class="device">
                    <div className="image">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/men.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <h4 className="text">
                     
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/comarigth.png"
                        }
                        className="quate"
                        alt="..."
                      />
                      This was the perfect hostel with the perfect location,
                      perfect room; I couldn’t think of a more perfect trip!
                      This was a great location as I was going to a conference
                      at the avitz center and it’s nearly a couple blocks walk.{" "}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/comaleft.png"
                        }
                        className="quate2"
                        alt="..."
                      />
                    </h4>
                    <h5 className="shubham">Shubham Yadav</h5>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                <div class="device">
                    <div className="image">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/men.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <h4 className="text">
                     
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/comarigth.png"
                        }
                        className="quate"
                        alt="..."
                      />
                      This was the perfect hostel with the perfect location,
                      perfect room; I couldn’t think of a more perfect trip!
                      This was a great location as I was going to a conference
                      at the avitz center and it’s nearly a couple blocks walk.{" "}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/other/comaleft.png"
                        }
                        className="quate2"
                        alt="..."
                      />
                    </h4>
                    <h5 className="shubham">Shubham Yadav</h5>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
          {/* <div class="shadow bottom">Bottom</div> */}
        </div>
      </section>
      <div className="other_image">
        <img
          src={
            process.env.PUBLIC_URL +
            "/assets/Images/HomePage/What-others-say.jpg"
          }
          className="other-img"
          alt="..."
        />
      </div>
      <div className="background_img"> </div>
      <div class="shadow bottom">Bottom</div>
    </>
  );
};

export default OtherSay;
