import React, { useEffect, useRef, useState,useContext } from "react";
import BlogDetails from "./BlogDetails/BlogDetails";
import BlogDetailsBanner from "./BlogDetailsBanner/BlogDetailsBanner";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
const RoomsAllDetails = () => {
  const topRef = useRef(null);
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div ref={topRef}>
      <BlogDetailsBanner />
      <BlogDetails  />
    </div>
  );
};
export default RoomsAllDetails;
