import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Context } from "../../../utils/context";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../PrivacyPolicyBanner/PrivacyPolicyBanner.css";
const PrivacyPolicyBanner = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home/all-banner`);
      setData(response?.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getSocialMedia();
  }, []);

  return (
    <>
      <section className="main">
        {/* Privacy Policy section start */}
        {/* <div className="banner">
          <div className="bg-img">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/Images/Rooms/Banner/Rooms_banner_img.png"
              }
              className="banner-img"
              alt="..."
            />
          </div>
          <div className="text-holder">
            <h1>Privacy Policy</h1>
          </div>
          <div className="overley"></div>
        </div> */}

        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
          }}
          showPagination={false}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {data &&
            data
              ?.filter((slide) => slide.banner_type === "Privacy Policy")
              .map((slide, index) => (
                <SwiperSlide>
                  <div key={index} className="banner">
                    <div className="bg-img">
                      {slide.mediaType === "video" ? (
                        <video
                          autoPlay
                          muted
                          loop
                          className="banner-img"
                        // style={{ width: "100%", height: "800px" }}
                        >
                          <source
                            src={IMG_URL + slide.image}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={IMG_URL + slide.image}
                          className="banner-img"
                          alt="slider-img"
                        // style={{ width: "100%", height: "800px" }}
                        />
                      )}
                    </div>
                    <div className="text-holder">
                      <h1>Privacy Policy</h1>
                    </div>

                    <div className="overley"></div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>

        {/* Privacy Policy section end */}

        <div className="icons">
          {socialMedia?.map((val, index) => (
            <div key={index}>
              <a href={val?.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={IMG_URL + val?.image}
                  className="img-fluid mt-2"
                  alt="..."
                />
              </a>
            </div>
          ))}

          {/* <div>
            <img
              src={process.env.PUBLIC_URL + "/assets/Images/banner/twitter.png"}
              className="img-fluid mt-3"
              alt="..."
            />
          </div>

          <div>
            <img
              src={
                process.env.PUBLIC_URL + "/assets/Images/banner/instagram.png"
              }
              className="img-fluid mt-3"
              alt="..."
            />
          </div>

          <div>
            <img
              src={
                process.env.PUBLIC_URL + "/assets/Images/banner/facebook.png"
              }
              className="img-fluid mt-3"
              alt="..."
            />
          </div> */}
          <div>
            <div class="vl"></div>
          </div>
          <div className="follow">
            <p className="us">Follow us</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default PrivacyPolicyBanner;
