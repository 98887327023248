import React, { useEffect, useRef } from "react";
import BlogBanner from "./BlogBanner/BlogBanner";
import { Traveller } from "./Traveller/Traveller";


const Blog = () => {

  const topRef = useRef(null);

  useEffect(() => {

    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div ref={topRef}>
      <BlogBanner />
      <Traveller />
    </div>
  );
};
export default Blog;
