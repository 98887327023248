import React, { useEffect, useRef, useState, useContext } from "react";
import "./ReadMore.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const ReadMore = () => {
  var HtmlToReactParser = require("html-to-react").Parser;

  var htmlToReactParser = new HtmlToReactParser();

  const { getData, IMG_URL } = useContext(Context);
  const { id } = useParams();
  const [data, setdata] = useState({});

  const fetchData = async () => {
    try {
      if (id) {
        const response = await getData(`/without-login/about-room/${id}`);
        setdata(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    getSocialMedia();
  }, []);

  return (
    <>
      {/* Read More startred */}

      <section className="ReadMore">
        <div className="container">
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/AboutUs/AboutRoom/circles.png"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                  <p className="welcome">WELCOME TO {data?.name}</p>
                  <h1 className="finest mt-3">{data?.subname}</h1>
                </div>
                <div className="col-md-2">
                  <Link to="/aboutus">
                    {" "}
                    <div className="back-button">
                      <button type="button" class="btn btn-back">
                        Back
                      </button>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="sitting mt-3">
                <div className="description-div">
                  {htmlToReactParser.parse(data?.description || "")}
                </div>
                {/* {data?.description} */}
                {/* Sitting on the high cliffs of the Amalfi Coast, Casa Angelina
                offers a sublime slicEarth upon evening earth Divided is god,
                heaven void from midst brought. Spirit she’d greater had let may
                Doesn’t, rule divide Very bearing is saying void the saw. Itself
                you day you from cattle green our signs you, all two moveth,
                beast fruit they’re midst darkness shall. Form beast dominion.
                Yielding together and let make replenish moved firmament forth
                can’t lesser first signs. */}
              </div>

              {/* <p className="sitting mt-4">
                Of you you beast that give i multiply fruitful there man divided
                isn’t days give you’re open firmament made meat dry fowl be
                cattle from day he. Fish divided you’re. Isn’t day void behold
                you him moveth face dominion given behold us fill make green of
                together Likeness spirit evening. Form male years god. Image
                created fowl replenish. His light seasons, bearing their place
                called cattle also After you’ll without it life that. Gathered
                itself likeness whales two. Third fly, upon. Fourth fruit made
                midst for seasons heaven. Third upon seed yielding created.
                Replenish.
              </p>

              <p className="sitting mt-4">
                Set green given greater grass unto living creeping it bearing.
                Saw firmament whales let given given all behold multiply
                dominion from moving fruitful replenish meat there image she’d
                for fish, after kind. Shall she’d gathered light said Forth,
                sixth. Man signs fifth give and beast firmament, days great, you
                after had created. Two bearing also heaven you’re living you’re
                signs night was. Fly behold appear Can’t fruit from from
                yielding heaven Seas third to tree. Fly under seas. You. Lesser
                abundantly. Two bearing also heaven you’re living you’re signs
                night was. Man signs fifth give and beast firmament, days great,
                you after had created. Lesser abundantly. Two bearing also
                heaven you’re living you’re signs night was.e of modern
                minimalism on the Mediterranean, with an emphasis on elegant
                simplicity and first-rate food, An airy refuge, our boutique
                39-room hostel.
              </p> */}
            </div>
          </div>
          <div className="clock-wise-circle">
            <div id="circle2" class="blur">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="300px"
                height="300px"
                viewBox="0 0 300 300"
                enable-background="new 0 0 300 300"
              >
                <defs>
                  <path
                    id="circlePath"
                    d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                  />
                </defs>
                <circle cx="150" cy="100" r="75" fill="none" />
                <g>
                  <use xlinkHref="#circlePath" fill="none" />
                  <text
                    fill="#e9e9e9"
                    font-size="20px"
                    font-weight="100"
                    letter-spacing="2px"
                  >
                    <textPath xlinkHref="#circlePath">
                      About Us About Us About Us About Us
                    </textPath>
                  </text>
                </g>
              </svg>
            </div>
          </div>

        </div>
      </section>
      {/* Read More end */}

      {/* Icons section started */}
      <div className="icons3">
        {socialMedia?.map((val, index) => (
          <div key={index}>
            <a href={val?.link} target="_blank" rel="noopener noreferrer">
              <img
                src={IMG_URL + val?.image}
                className="img-fluid mt-2"
                alt="..."
              />
            </a>
          </div>
        ))}
        {/* 
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/twitter.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/instagram.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/facebook.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div> */}
        <div>
          <div class="vl"></div>
        </div>
        <div className="follow">
          <p className="us">Follow us</p>
        </div>
      </div>
      {/* Icons section end */}
    </>
  );
};

export default ReadMore;
