import { React, useRef, useEffect, useState, useContext } from "react";
import "./Discover.css";
import { Context } from "../../../utils/context";
import Play from "../animation/json/Play";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Discover = () => {
  const vidRef = useRef();
  const [vdoDisplay, setDisplay] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/videos/all-videos`);
      setData(response?.data);
      if (response?.data && response.data.length > 0) {
        setSelectedVideo(response.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openPlayVdo = (e) => {
    setDisplay(true);
  };
  const closePlayVdo = (e) => {
    setDisplay(false);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    vidRef.current?.play();
  }, [vdoDisplay]);
  return (
    <>
      <section className="discover">
        <div className="blur"
          style={{
            display: vdoDisplay === true ? "blur" === "block" : "none",
          }}
        ></div>
        <div className="text">
          {data?.map((slide, index) => (
            <div className="content" key={index}>
              <h5 className="explore text-center">{slide?.name}</h5>
              <h1 className="place text-center mt-4">
                {parse(slide?.description || "")}
              </h1>
              <div className="play-button" onClick={openPlayVdo}>
                <Play />
              </div>
            </div>
          ))}
        </div>
        <div className="video"
          style={{
            display: vdoDisplay === true ? "block" : "none",
          }}
        >
          {data.map((slide, index) => (
            <div key={index}>
              <video
                key={index}
                src={IMG_URL + slide?.video}
                ref={vidRef}
                muted
                loop
                className="video-class-grid"
              />
              <div className="close" onClick={closePlayVdo}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Discover;
