import React, { useEffect, useState, useContext } from "react";

import "../AboutVerified/AboutVerified.css";
import Verified from "../animation/json/Verified";
import Experience from "../animation/json/Experience";

import MoneyBack from "../animation/json/MoneyBack";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";

const AboutVerified = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/about-verified`);
      setData(response?.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="about-verified">
        <div className="container">
          <div className="row">
            {data
              ?.filter((val) => val.lottie_type === "Verified professionals")
              .map((val, index) => (
                <div className="col-md-4" key={index}>
                  <Verified />
                  <h5 className="verified mt-2">{val.name}</h5>
                  <p className="cur mt-3">{parse(val.description)}</p>
                </div>
              ))}
            {data
              ?.filter((val) => val.lottie_type === "Trusted & Experienced")
              .map((val, index) => (
                <div className="col-md-4">
                  <Experience />
                  <h5 className="verified mt-2">{val.name}</h5>
                  <p className="cur mt-3">{parse(val.description)}</p>
                </div>
              ))}
            {data
              ?.filter((val) => val.lottie_type === "Money-back guarantee")
              .map((val, index) => (
                <div className="col-md-4">
                  <MoneyBack />
                  <h5 className="verified mt-2">{val.name}</h5>
                  <p className="cur mt-3">{parse(val.description)}</p>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutVerified;
