import React, { useState, useEffect, useRef, useContext } from "react";
import "./HostelForm.css";
import date_img from "../icon/calendar.png";
import { useParams } from "react-router-dom";
import { Context } from ".././../utils/context";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import DateTimePicker from "react-datetime-picker";

const HostelForm1 = () => {
  const [startDate, setStartDate] = useState(new Date());

  // const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const [arrivingDate, setArrivingDate] = useState(new Date());
  const [departingDate, setDepartingDate] = useState(new Date());
  const [arrivingTime, setArrivingTime] = useState("00:00");
  const [departingTime, setDepartingTime] = useState("00:00");
  const [arriving_time_marker, setArriving_time_marker] = useState("");
  const [diparting_time_marker, setDeparting_time_marker] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [cities, setcities] = useState();
  const [data, setData] = useState([]);
  const [roomName, setRoomName] = useState(null);

  const topRef = useRef(null);
  const today = new Date();
  const { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await getData(`/without-login/room/all-room/${id}`);
      setData(response?.data);
      setRoomName(data?.name);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataForRoom = async () => {
      await fetchData();
    };

    fetchDataForRoom();

    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  const { postData, getData } = useContext(Context);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    street: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
    mobile_number: "",
    email: "",
    arriving_Date: "",
    arriving_time: "",
    arriving_time_marker: "",
    departing_Date: "",
    departing_time: "",
    departing_time_marker: "",
    no_of_adults: "",
    payment_method: "",
    request: "",
    roomName: data?.name,
    roomId: null,
  });

  const [validationMessages, setValidationMessages] = useState({
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    mobile_number: "",
    email: "",
    arriving_Date: "",
    arriving_time: "",
    departing_Date: "",
    departing_time: "",
    no_of_adults: "",
    payment_method: "",
    request: "",
  });

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const [modalShow, setModalShow] = React.useState(false);

  const handleShow = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location = "/rooms";
      setModalShow(false);
    }, 3000);
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {};

    // Perform validation checks and update validation messages
    if (!formData.first_name?.trim()) {
      newValidationMessages.first_name = "First Name is required";
      isValid = false;
    } else if (!/^[A-Za-z]+$/.test(formData.first_name.trim())) {
      newValidationMessages.first_name = "Enter Valid First Name";
      isValid = false;
    }

    if (!formData.last_name?.trim()) {
      newValidationMessages.last_name = "Last Name is required";
      isValid = false;
    } else if (!/^[A-Za-z]+$/.test(formData.last_name.trim())) {
      newValidationMessages.last_name = "Enter Valis Last Name";
      isValid = false;
    }

    // Validate email
    if (!formData.email?.trim()) {
      newValidationMessages.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newValidationMessages.email = "Invalid email format";
      isValid = false;
    }

    if (!formData.street?.trim()) {
      newValidationMessages.street = "Street is required";
      isValid = false;
    }

    if (!formData.city?.trim()) {
      newValidationMessages.city = "City is required";
      isValid = false;
    }

    if (!formData.state) {
      newValidationMessages.state = "State is required";
      isValid = false;
    }

    if (!formData.country) {
      newValidationMessages.country = "Country is required";
      isValid = false;
    }

    if (!formData.pincode?.trim()) {
      newValidationMessages.pincode = "Pincode is required";
      isValid = false;
    } else if (!/^\d{6}$/.test(formData.pincode.trim())) {
      newValidationMessages.pincode = "Pincode must be a 6-digit number";
      isValid = false;
    }

    if (!formData.mobile_number?.trim()) {
      newValidationMessages.mobile_number = "Mobile Number is required";
      isValid = false;
    } else if (
      !/^(\+\d{1,4}[-\s]?)?\d{10}$/.test(formData.mobile_number.trim())
    ) {
      newValidationMessages.mobile_number =
        "Mobile No must be a 10-digit number";
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const validateEmail = (email) => {
    const emailRegex =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/;

    if (!emailRegex.test(email)) {
      return false; // Invalid email format
    }

    const [, domain] = email.split("@");

    // Check if the domain is already used or if it contains multiple domain segments
    if (usedDomains.has(domain)) {
      return false; // Invalid domain or already used
    }

    // Add the domain to the set to mark it as used
    usedDomains.add(domain);

    return true; // Valid email
  };
  // Initialize the set to store used domains
  const usedDomains = new Set();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate form data
      if (!validateForm()) {
        // If validation fails, return early
        return;
      }
      // Create FormData object
      const formDataToSend = new FormData();

      // Append form fields to FormData
      formDataToSend.append("first_name", formData.first_name);
      formDataToSend.append("last_name", formData.last_name);
      formDataToSend.append("street", formData.street);
      formDataToSend.append("country", formData.country);
      formDataToSend.append("city", formData.city);
      formDataToSend.append("state", selectedState);
      formDataToSend.append("pincode", formData.pincode);
      formDataToSend.append("mobile_number", formData.mobile_number);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("arriving_Date", arrivingDate.toISOString());
      formDataToSend.append("arriving_time", arrivingTime);
      formDataToSend.append("arriving_time_marker", arriving_time_marker);

      formDataToSend.append("departing_Date", departingDate.toISOString());
      formDataToSend.append("departing_time", departingTime);
      formDataToSend.append("departing_time_marker", diparting_time_marker);
      formDataToSend.append("no_of_adults", formData.no_of_adults);
      formDataToSend.append("payment_method", formData.payment_method);
      formDataToSend.append("request", formData.request);
      formDataToSend.append("roomName", data ? data.name : "");

      console.log(formDataToSend);

      // Make POST request using axios
      const response = await postData(
        "/without-login/registration",
        formDataToSend
      );

      if (response?.success) {
        setModalShow({ code: response.code, message: response.message });
        setModalShow(true);
        setTimeout(() => {
          window.location = "/";
          setModalShow(false);
        }, 3000);
      } else {
        console.error("Submission failed:", response?.error);
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    setSelectedCountry(selectedCountryId);
    setSelectedState("");
    getAllState(selectedCountryId);

    setFormData((prevData) => ({
      ...prevData,
      country: selectedCountryId,
      state: "",
    }));
  };

  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    setSelectedState(selectedStateId);
    // setSelectedCity("");
    // getAllCity(selectedStateId);

    setFormData((prevData) => ({
      ...prevData,
      state: selectedStateId,
      city: "",
    }));
  };

  const GetAllCountry = async () => {
    const response = await getData("/without-login/apis/allcountry");
    console.log(response);
    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "country_id",
          label: data.name,
        });
      });
    }
    setCountries(option);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  async function getAllState(id) {
    const response = await getData(`/without-login/apis/allstate/${id}`);

    if (response?.success) {
      setStates(response.data);
    }
  }

  async function getAllCity(id) {
    const response = await getData(`/without-login/apis/allcity/${id}`);

    if (response.success) {
      setcities(response.data);
    }
  }

  return (
    <>
      <section className="form" ref={topRef}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-11 col-xl-9 col-md-11">
              <div className="form-content">
                <div className="text p-2">
                  <h3>Hostel Reservation Form</h3>

                  <p className="pb-4">Please Complete the form below.</p>

                  <p className="para-two">
                    Your registration will be verified prior to your arrival.
                  </p>
                </div>

                <form>
                  <div className="row p-2">
                    <div className="col-lg-5 ">
                      <label for="firstName" classname="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        aria-label="First name"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            first_name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">
                        {validationMessages.first_name}
                      </p>
                    </div>
                    <div className="col-lg-1"> </div>
                    <div className="col-lg-6">
                      <label for="firstName" class="form-label"></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        aria-label="Last name"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            last_name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">
                        {validationMessages.last_name}
                      </p>
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                      <label htmlFor="inputAddress2" className="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAddress2"
                        placeholder="street Address"
                        onChange={(e) =>
                          setFormData({ ...formData, street: e.target.value })
                        }
                      />
                      <p className="text-danger">{validationMessages.street}</p>
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="inputCountry" className="form-label">
                        Country
                      </label>
                      <select
                        className="form-select"
                        id="inputCountry"
                        onChange={handleCountryChange}
                        value={selectedCountry}
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        {countries &&
                          countries.map((country) => (
                            <option key={country.value} value={country.value}>
                              {country.label}
                            </option>
                          ))}
                      </select>
                      <p className="text-danger">
                        {validationMessages.country}
                      </p>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="inputState" className="form-label">
                        State
                      </label>
                      <select
                        className="form-select"
                        id="inputState"
                        onChange={handleStateChange}
                        value={selectedState}
                      >
                        <option value="" disabled>
                          Select State
                        </option>
                        {states &&
                          states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                      <p className="text-danger">{validationMessages.state}</p>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="inputCity" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCity"
                        placeholder="City"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            city: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{validationMessages.city}</p>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="inputZip" className="form-label">
                        Pincode
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onKeyPress={handleKeyPressContact}
                        id="inputZip"
                        pattern="[0-9]{10}"
                        maxLength="6"
                        placeholder="Pincode"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pincode: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">
                        {validationMessages.pincode}
                      </p>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneNumber"
                        onKeyPress={handleKeyPressContact}
                        pattern="[0-9]{10}"
                        maxLength="10"
                        placeholder="Phone Number"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            mobile_number: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">
                        {validationMessages.mobile_number}
                      </p>
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <label htmlFor="inputEmail4" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        id="inputEmail4"
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                      <p className="text-danger">{validationMessages.email}</p>
                    </div>
                  </div>
                  {/* <div className="row">
                      

                        <div className="col-lg-4 col-md-4">
                      <label htmlFor="inputCity" className="form-label"></label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCity"
                        placeholder="City"
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                      />
                      <p className="text-danger">{validationMessages.city}</p>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label
                        htmlFor="inputState"
                        className="form-label"
                      ></label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputdtate"
                        placeholder="State"
                        onChange={(e) =>
                          setFormData({ ...formData, state: e.target.value })
                        }
                      />
                      <p className="text-danger">{validationMessages.state}</p>
                    </div> 
                         <div className="col-lg-4 col-md-4">
                      <label htmlFor="inputZip" className="form-label"></label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputZip"
                        placeholder="Pincode"
                        onChange={(e) =>
                          setFormData({ ...formData, pincode: e.target.value })
                        }
                      />
                      <p className="text-danger">{validationMessages.pincode}</p>
                    </div>
                      </div> */}

                  {/* <div className="row p-2 ">
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 col-xxl-6 col-12">
                      <label htmlFor="firstName" className="form-label">
                        Arrival - Date and Time
                      </label>

                      <div className="all-content">
                        <div className="date-sec">
                          <div className="input-group">
                            <div>
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img
                                  src={date_img}
                                  className="date_img"
                                  alt="date"
                                />
                              </span>
                            </div>

                            <div className="DatePick">
                              <DatePicker
                                selected={arrivingDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                isClearable={false}
                                minDate={today}
                                onChange={(date) => setArrivingDate(date)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-xl-2 m-2">
                          <div className="input-group">
                            <input
                              type="time"
                              className="form-control"
                              id="timeInput"
                              placeholder="HH:MM"
                              value={arrivingTime}
                              onChange={(e) => setArrivingTime(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12">
                      <label for="firstName" classname="form-label">
                        Department -Date and Time
                      </label>

                      <div className="all-content ">
                        <div className=" date-sec">
                          <div className="">
                            <div className="input-group">
                              <div>
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img src={date_img} className="date_img" />
                                </span>
                              </div>

                              <div className="DatePick">
                                <DatePicker
                                  selected={departingDate}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  isClearable={false}
                                  minDate={arrivingDate}
                                  onChange={(date) => setDepartingDate(date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2  m-2">
                          <div className="input-group">
                            <input
                              type="time"
                              className="form-control"
                              id="timeInput"
                              value={departingTime}
                              onChange={(e) => setDepartingTime(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row p-2 ">
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 col-xxl-6 col-12 ">
                  <label for="firstName" classname="  form-label">
                    Arrival -Date and Time
                  </label>

                  <div className="all-content  ">
                    <div className="  date-sec">
                      <div className=" ">
                        <div className="input-group ">
                          <div>
                            <span class="input-group-text" id="basic-addon1">
                              <img src={date_img} className="date_img" />
                            </span>
                          </div>

                          <div className="DatePick">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              isClearable={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-2 col-lg-2 col-sm-4 col-5 m-2">
                      <div className="input-group">
                        <input
                          type="time"
                          className="form-control "
                          id="timeInput"
                          placeholder="HH:MM"
                          
                          
                        />
                      </div>
                      
                    </div>
                    <div className=" checkbox col-md-3   pt-2">
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input rounds"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            defaultValue="option1"
                          />
                        
                          <p className="">AM</p>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input rounds"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            defaultValue="option2"
                          />
                        
                          <p>PM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 col-xxl-6 col-12">
                  <label for="firstName" classname="form-label">
                   Departure - Date and Time
                  </label>

                  <div className="all-content ">
                    <div className=" date-sec">
                      <div className="">
                        <div className="input-group">
                          <div>
                            <span class="input-group-text" id="basic-addon1">
                              <img src={date_img} className="date_img" />
                            </span>
                          </div>

                          <div className="DatePick">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              isClearable={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-5 m-2">
                      <div className="input-group">
                        <input
                          type="time"
                          className="form-control"
                          id="timeInput"
                        />
                      </div>
                    </div>
                    <div className=" checkbox  col-md-3   pt-2">
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input rounds"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            defaultValue="option1"
                          />

                          <p>AM</p>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input rounds"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            defaultValue="option2"
                          />

                          <p>PM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div> */}

                  {/* am pm */}
                  {/* <div className="row p-2 ">
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 col-xxl-6 col-12 ">
                      <label for="firstName" classname="  form-label">
                        Arrival -Date and Time
                      </label>

                      <div className="all-content  ">
                        <div className="  date-sec">
                          <div className=" ">
                            <div className="input-group ">
                              <div>
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img src={date_img} className="date_img" />
                                </span>
                              </div>

                              <div className="DatePick">
                                <DatePicker
                                  selected={arrivingDate}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  isClearable={false}
                                  minDate={today}
                                  onChange={(date) => setArrivingDate(date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5 m-2">
                          <div className="input-group">
                            <input
                              type="time"
                              className="form-control"
                              id="timeInput"
                              placeholder="HH:MM"
                              value={arrivingTime}
                              onChange={(e) => setArrivingTime(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="checkbox col-md-3 pt-2">
                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input rounds"
                                type="radio"
                                name="inlineRadioOptions1"
                                id="inlineRadio1"
                                defaultValue="option1"
                                onChange={(e) => setArriving_time_marker("AM")}
                              />
                              <p>AM</p>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input rounds"
                                type="radio"
                                name="inlineRadioOptions1"
                                id="inlineRadio2"
                                defaultValue="option2"
                                onChange={(e) =>
                                  // setFormData({
                                  //   ...formData,
                                  //   arriving_time_marker: "PM",
                                  // })
                                  setArriving_time_marker("PM")
                                }
                              />
                              <p>PM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12">
                      <label for="firstName" classname="form-label">
                        Departure - Date and Time
                      </label>

                      <div className="all-content ">
                        <div className=" date-sec">
                          <div className="">
                            <div className="input-group">
                              <div>
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img src={date_img} className="date_img" />
                                </span>
                              </div>

                              <div className="DatePick">
                                <DatePicker
                                  selected={departingDate}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  isClearable={false}
                                  minDate={
                                    new Date(
                                      new Date(arrivingDate).getTime() +
                                        24 * 60 * 60 * 1000
                                    )
                                  }
                                  onChange={(date) => setDepartingDate(date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5  m-2">
                          <div className="input-group">
                            <input
                              type="time"
                              className="form-control"
                              id="timeInput"
                              value={departingTime}
                              onChange={(e) => setDepartingTime(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="checkbox col-md-3 pt-2">
                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input rounds"
                                type="radio"
                                name="inlineRadioOptions2"
                                id="inlineRadio3"
                                defaultValue="option3"
                                onChange={(e) => setDeparting_time_marker("AM")}
                              />
                              <p>AM</p>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input rounds"
                                type="radio"
                                name="inlineRadioOptions2"
                                id="inlineRadio4"
                                defaultValue="option4"
                                onChange={(e) => setDeparting_time_marker("PM")}
                              />
                              <p>PM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row p-2">
                    <div className="col-lg-5 ">
                      <label htmlFor="phoneNumber" className="form-label">
                        Number of Adults
                      </label>
                      <input
                        type="tel"
                        onKeyPress={handleKeyPressContact}
                        className="form-control"
                        id="Number"
                        placeholder="ex.23"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            no_of_adults: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-1 "> </div>
                    <div className="  col-lg-6 ">
                      <label
                        htmlFor="inputEmail4"
                        className="form-label email-sec "
                      >
                        Payment Method
                      </label>
                      <div>
                        <div className="px-4 form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            defaultValue="option1"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                payment_method: "Check",
                              })
                            }
                          />
                          <label
                            className="  form-check-label "
                            htmlFor="inlineRadio1"
                          ></label>{" "}
                          Only Cash
                        </div>
                        {/* <div className="px-4  form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            defaultValue="option2"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                payment_method: "Paypal",
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          ></label>{" "}
                          Paypal
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 p-2">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Do you have any special request?
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Type here.."
                      rows={4}
                      defaultValue={""}
                      onChange={(e) =>
                        setFormData({ ...formData, request: e.target.value })
                      }
                    />
                  </div>

                  <div className="custum-btn ">
                    <button
                      type="button"
                      class="btn text-white  "
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>

                    <Modal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      centered
                      className="Home-PopUp"
                    >
                      <Modal.Body>
                        <h1 className="successfully">
                          Booking Registerd successfully
                        </h1>
                        <h1 className="Thanks">Thank You!</h1>
                      </Modal.Body>
                    </Modal>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostelForm1;
