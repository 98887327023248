import React, { useContext, useEffect, useRef, useState } from "react";

import "../Gallery/Gallery.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Context } from "../../utils/context";

const Gallery = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [mainImageSrc, setMainImageSrc] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/gallery`);
      setData(response?.data);
      if (mainImageSrc.length === 0) {
        const data = await response?.data?.map(
          (val) => val?.gallery_images[0]?.image
        );
        await setMainImageSrc(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getSocialMedia();
  }, []);

  const handleBigImageClick = async (index) => {
    await setBigIndex(index);
    const showFullImg = document.querySelector(".show-full-img");

    // Toggle the "active" class to show/hide the .show-full-img element
    showFullImg.classList.toggle("active");
  };

  const handleCloseButtonClick = () => {
    const showFullImg = document.querySelector(".show-full-img");

    // Hide the .show-full-img element by removing the "active" class
    showFullImg.classList.remove("active");
  };

  const handleMainImageClick = async (index, image) => {
    await setMainImageSrc((prevMainImageSrc) => {
      const updatedImages = [...prevMainImageSrc];
      updatedImages[index] = image;
      return updatedImages;
    });
  };

  const [bigindex, setBigIndex] = useState(0);

  return (
    <>
      <section className="main">
        {/*Gallery section start */}
        <div className="gallery">
          {data?.map((val, index) => (
            <div className="row justify-content-center me-0 ms-0">
              <div className="col-xxl-10 col-md-10 col-11 mx-auto ">
                <div className="row justify-content-center">
                  <div className="col-xxl-3 col-xl-3 col-md-3 col-sm-4 col-11">
                    <div className="text-holder">
                      <div class="all-circles">
                        <div class="circles">
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                        </div>
                        <div class="circles">
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                        </div>
                        <div class="circles">
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                        </div>
                        <div class="circles">
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                          <div class="circle "></div>
                        </div>
                      </div>
                      <div class="heading">
                        <p>WELCOME TO {val.name}</p>
                        <h1 className="mt-2 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3">
                          {val.subname}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-9 col-xl-9 col-md-9 col-sm-8 col-11">
                    <div className="gallery-main-slider">
                      <div className="background-img">
                        <div className="bg">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Gallery/background_img.png"
                            }
                            className="bg-img "
                            alt="..."
                          />
                        </div>
                        <div className="img-holder">
                          <img
                            src={IMG_URL + mainImageSrc[index]}
                            className="main-img "
                            alt="..."
                            onClick={() => handleBigImageClick(index)}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-start">
                        <div className="col-md-7 col-sm-9 col-10">
                          <Swiper
                            spaceBetween={5}
                            slidesPerView={4}
                            pagination={{
                              clickable: true,
                            }}
                            showPagination={false}
                            navigation={true}
                            modules={[Navigation]}
                            loop={true}
                            className="mySwiper"
                            breakpoints={{
                              0: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                              },
                              640: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                              },
                              768: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                              },
                              1024: {
                                slidesPerView: 4,
                                spaceBetween: 5,
                              },
                              1200: {
                                slidesPerView: 4,
                                spaceBetween: 5,
                              },
                            }}
                          >
                            {val?.gallery_images?.map((val1, index2) => (
                              <SwiperSlide key={index2}>
                                <div
                                  className="img-block"
                                  onClick={() => {
                                    handleMainImageClick(index, val1?.image);
                                  }}
                                >
                                  <img
                                    src={IMG_URL + val1?.image}
                                    className="slider-img"
                                    alt="..."
                                  />
                                </div>
                              </SwiperSlide>
                            ))}

                            {/* <SwiperSlide>
                              <div className="img-block"  onClick={() => { handleMainImageClick("/assets/Images/Gallery/gallery_img_2.png") }}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Gallery/gallery_img_2.png"
                                  }
                                  className="slider-img"
                                  alt="..."
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="img-block"  onClick={() => { handleMainImageClick("/assets/Images/Gallery/gallery_img_1.png") }}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Gallery/gallery_img_1.png"
                                  }
                                  className="slider-img"
                                  alt="..."
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide >
                              <div className="img-block"  onClick={() => { handleMainImageClick("/assets/Images/Gallery/gallery_img_4.png") }}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Gallery/gallery_img_4.png"
                                  }
                                  className="slider-img"
                                  alt="..."
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="img-block"  onClick={() => { handleMainImageClick("/assets/Images/Gallery/gallery_img_1.png") }} >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Gallery/gallery_img_1.png"
                                  }
                                  className="slider-img"
                                  alt="..."
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="img-block"  onClick={() => { handleMainImageClick("/assets/Images/Gallery/gallery_img_2.png") }}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Gallery/gallery_img_2.png"
                                  }
                                  className="slider-img"
                                  alt="..."
                                />
                              </div>
                            </SwiperSlide> */}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="show-full-img">
            <img
              src={IMG_URL + mainImageSrc[bigindex]}
              className="full-main-img"
              alt="..."
            />
            <div class="close" onClick={handleCloseButtonClick}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="xmark"
                class="svg-inline--fa fa-xmark "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                ></path>
              </svg>
            </div>
          </div>

          <div className="icons-five">
            {socialMedia?.map((val, index) => (
              <div key={index}>
                <a href={val?.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={IMG_URL + val?.image}
                    className="img-fluid mt-2"
                    alt="..."
                  />
                </a>
              </div>
            ))}

            {/* <div>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Images/banner/twitter.png"
                }
                className="img-fluid mt-3"
                alt="..."
              />
            </div>

            <div>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Images/banner/instagram.png"
                }
                className="img-fluid mt-3"
                alt="..."
              />
            </div>

            <div>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Images/banner/facebook.png"
                }
                className="img-fluid mt-3"
                alt="..."
              />
            </div> */}
            <div>
              <div class="vl"></div>
            </div>
            <div className="follow">
              <p className="us">Follow us</p>
            </div>
          </div>
        </div>

        {/* Gallery section end */}
      </section>
    </>
  );
};
export default Gallery;
