/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import PrivacyPolicyTabs from "./PrivacyPolicyTabs/PrivacyPolicyTabs"
import PrivacyPolicyBanner from "./PrivacyPolicyBanner/PrivacyPolicyBanner"

const PrivacyPolicy = () => {
  const topRef = useRef(null);
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div ref={topRef}>
      <PrivacyPolicyBanner/>
      <PrivacyPolicyTabs/>
      </div>
    </>
  );
};
export default PrivacyPolicy;
