import React, { useEffect, useRef } from "react";
import HomePageBanner from "./HomePageBanner/HomePageBanner";
import LuxurySection from "./LuxurySection/LuxurySection";
import OurPageType from './OurRoomType/OurRoomType'
import PlanYourNextStaycation from './PlanYourNextStaycation/PlanYourNextStaycation'
import Facilities from "./Facilities/Facilities";
import OtherSay from "./OtherSay/OtherSay";
import BlogFeed from "./BlogFeed/BlogFeed";
import DownloadBrochure from "./DownloadBrochure/DownloadBrochure";
//  import Header1 from "./Header1/Header1";


const HomePage = ({ refNew }) => {

  const topRef = useRef(null);

  useEffect(() => {

    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div ref={topRef}>

      <HomePageBanner />
      <LuxurySection />

      <OurPageType />
      <PlanYourNextStaycation />
      <div ref={refNew}>
        <Facilities />
      </div>
      <DownloadBrochure/>
      <OtherSay />
      <BlogFeed />
      {/* <Header1/> */}
    </div>
  );
};
export default HomePage;

