/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useRef, useEffect, useState, useContext } from "react";
import "../PlanYourNextStaycation/PlanYourNextStaycation.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import parse from "html-react-parser";

library.add(fas);

const PlanYourNextStaycation = () => {
  const vidRef = useRef();
  const [vdoDisplay, setDisplay] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/videos/all-videos`);
      setData(response?.data);
      if (response?.data && response.data.length > 0) {
        setSelectedVideo(response.data[0].id);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openPlayVdo = (e) => {
    setDisplay(true);
  };
  const closePlayVdo = (e) => {
    setDisplay(false);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    vidRef.current?.play();
  }, [vdoDisplay]);
  return (
    <>

      <section className="Plan-Your-Next-Staycation">
        <div className="blur"
          style={{
            display: vdoDisplay === true ? "blur" === "block" : "none",
          }}
        ></div>
        <div className="text">
          {data?.map((slide, index) => (
            <div key={index}>
              <h1 className="heading text-center">{parse(slide.description || "")}</h1>
              <div className="underline"></div>
              <div className="play-button" onClick={openPlayVdo}>
                <FontAwesomeIcon icon="fa-solid fa-play" />
              </div>
            </div>
          ))}
        </div>
        <div className="video"
          style={{
            display: vdoDisplay === true ? "block" : "none",
          }}
        >
          {data?.map((slide, index) => (
            <div key={index}>
              <video
                key={index}
                src={IMG_URL + slide.video}
                ref={vidRef}
                muted
                loop
                className="video-class-grid"
              />
              <div className="close" onClick={closePlayVdo}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              </div>
            </div>
          ))}

        </div>



      </section>
    </>
  );
};
export default PlanYourNextStaycation;
