import React, { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import "../AboutRoom/AboutRoom.css";
import { Link } from "react-router-dom";
const AboutRoom = () => {
  var HtmlToReactParser = require("html-to-react").Parser;

  var htmlToReactParser = new HtmlToReactParser();
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/about-room`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [socialMedia, setSocialMedia] = useState([]);
  const getSocialMedia = async () => {
    try {
      const response = await getData(
        `/without-login/social-media/allsocialmedia`
      );
      setSocialMedia(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getSocialMedia();
  }, []);

  return (
    <>
      {/* About Room Section started */}

      <section className="About-Room">
        {data?.map((val) => (
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="image-1">
                  <img
                    src={IMG_URL + val?.image1}
                    className="image-one"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="main-content">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/AboutUs/AboutRoom/circles.png"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                  <p className="welcome">{val?.name}</p>
                  <h1 className="finest mt-3">{val?.subname}</h1>
                  <p className="sitting mt-3">
                    <div className="description-div">
                      {htmlToReactParser.parse(val?.description)}
                    </div>
                  </p>
                  <h5 className="read mt-2"></h5>
                  <Link to={`/readmore/${val?.id}`}>
                    <button className="button-read">Read More >>> </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="image-sketch">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/AboutUs/AboutRoom/sketch.png"
                    }
                    className="sketch"
                    alt="..."
                  />
                </div>
                <div id="container">
                  <div id="circle" class="blur">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlinkHref="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="300px"
                      height="300px"
                      viewBox="0 0 300 300"
                      enable-background="new 0 0 300 300"
                    >
                      <defs>
                        <path
                          id="circlePath"
                          d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                        />
                      </defs>
                      <circle cx="150" cy="100" r="75" fill="none" />
                      <g>
                        <use xlinkHref="#circlePath" fill="none" />
                        <text fill="#a1a1a1" fontSize="23px">
                          <textPath xlinkHref="#circlePath">
                            {" "}
                            ABOUT US ABOUT US ABOUT US{" "}
                          </textPath>
                        </text>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div className="image-2">
                  <img
                    src={IMG_URL + val?.image2}
                    className="young"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* About Room Section end*/}

      {/* Icons section started */}
      <div className="icons2">
        {socialMedia?.map((val, index) => (
          <div key={index}>
            <a href={val?.link} target="_blank" rel="noopener noreferrer">
              <img
                src={IMG_URL + val?.image}
                className="img-fluid mt-2"
                alt="..."
              />
            </a>
          </div>
        ))}

        {/* <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/twitter.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/instagram.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Images/banner/facebook.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div> */}
        <div>
          <div class="vl"></div>
        </div>
        <div className="follow">
          <p className="us">Follow us</p>
        </div>
      </div>
      {/* Icons section end */}
    </>
  );
};

export default AboutRoom;
