import React from 'react'
import Lottie from "react-lottie";
import Experience_json from "../../animation/Experience.json"
import './Verified.css'
const Experiance = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Experience_json,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <>
     <section className='lottie-animation-div'>
        <div className='Footer-lottie mt-4 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0'>
          <Lottie 
            options={defaultOptions} 
             />
        </div>
      </section>
    </>
  )
}

export default Experiance