import React, { useEffect, useRef, useState ,useContext} from "react";
import { useParams } from "react-router-dom";
import RoomsDetailsBanner from "./RoomsDetailsBanner/RoomsDetailsBanner";
import RoomsDetailsInfo from "./RoomsDetailsInfo/RoomsDetailsInfo";
import RoomsDetailsSlider from "./RoomsDetailsSlider/RoomsDetailsSlider";
import { Context } from "../../../src/utils/context";

const RoomsAllDetails = () => {
  const { IMG_URL ,getData } = useContext(Context);
  const { id } = useParams();
  const [data, setData , ]  = useState([]);

  const topRef = useRef(null);
  const [roomDetails, setRoomDetails] = useState(null);

  const fetchData = async () => {
    try {
      const response = await getData(`/without-login/room/all-room/${id}`);
      setData(response?.data);
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataForRoom = async () => {
      await fetchData();
    
    };

    fetchDataForRoom();

    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  return (
    
    <div ref={topRef}>
       {data.length === 0 ? (
      <p>Loading...</p>
    ) : (
      <>
      <RoomsDetailsBanner roomDetails={data} />
      <RoomsDetailsInfo roomDetails={data} />
      <RoomsDetailsSlider roomDetails={data} />
      </>
      )}
    </div>
  );
};
export default RoomsAllDetails;
