import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Context } from "../../../utils/context";

import "./BlogFeed.css";


import "swiper/css";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const BlogFeed = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/home/all-blogfeed`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatDate(dateTimeString) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString(undefined, options);
  }



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  return (
    <section className="BlogFeed">
      <div className="img-section ">
        <div className="text ">
          <div className="first-heading mt-3 mt-sm-4 mt-md-3 mt-lg-3 mt-xl-4 mt-xxl-5">
            <p>ARTICLES</p>
          </div>
          <div className="second-heading">
            <p>Blog Feed</p>
          </div>
        </div>
        <div className="swiper-section ">
          <Swiper
            spaceBetween={30}
            centeredSlides={false}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={2}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
              991: {
                slidesPerView: 1,
              },
              1200: {
                slidesPerView: 2,
              },
              1400: {
                slidesPerView: 2,
              },
              1600: {
                slidesPerView: 2,
              },
              1880: {
                slidesPerView: 3,
              },
              2690: {
                slidesPerView: 3,
              },
            }
            }
          >
            {data && data?.map((item) => (
              <SwiperSlide className="">
                <div className="slider-section">
                  <div className="imgs">
                    <div className="row blog-card">
                      <div className="col-xxl-5 col-xl-5 col-lg-4 col-sm-4 col-md-4 col-12 p-0">
                        <img
                          className="fix-editor1"
                          src={IMG_URL + item.image}

                        />
                      </div>
                      <div className="col-xxl-7 col-xl-7 col-lg-8 col-sm-8 col-md-8 col-12 p-0">
                        <div className="inner-content">
                          <p>{formatDate(item.createdAt)}</p>
                          <p className="heading" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                            {item?.name}
                          </p>


                          <div className="icon-sec d-flex">
                            <div className="msg-icon ">
                              {/* <img
          src={
            process.env.PUBLIC_URL +
            "/assets/Images/Blog/msg.png"
          }
          className="icon-size "
          alt="Image 2"
        /> */}
                            </div>
                            <div>
                              {/* <img
          src={
            process.env.PUBLIC_URL +
            "/assets/Images/Blog/heart.png"
          }
          className="icon-size"
          alt="Image 2"
        /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}

          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BlogFeed;
