/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import "./RoomsDetailsInfo.css";
import { useParams } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
library.add(fas);

const RoomsDetailsInfo = ({ roomDetails }) => {
  var HtmlToReactParser = require("html-to-react").Parser;

  var htmlToReactParser = new HtmlToReactParser();
  const { getData, IMG_URL } = useContext(Context);
  const [facilities, setFacilities] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const fetchData = async () => {
    try {
      const response = await getData(
        `/without-login/room/all-room-facilities/${roomDetails.id}`
      );

      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  useEffect(() => {
    const fetchDataForRoom = async () => {
      const roomFacilities = await fetchData();
      if (roomFacilities) {
        setFacilities(roomFacilities);
      }
    };

    fetchDataForRoom();
  }, [roomDetails.id]);

  useEffect(() => {
    console.log("roomaDetails :- ", roomDetails);
  }, [roomDetails]);

  return (
    <>
      <section className="main">
        <div className="Rooms-info">
          <div className="heading-section">
            <div className="text-holder">
              <h1>Rooms</h1>
              <h3>{roomDetails.name}</h3>
              <div className="description-div">
                <p>{htmlToReactParser.parse(roomDetails.description)}</p>
              </div>
            </div>
          </div>

          <div className="info-table">
            <div className="row ms-0 me-0">
              <div className="col-xxl-11 col-lg-11 col-md-12 mx-auto ">
                <div className="row justify-content-center me-0 ms-0 p-2">
                  <div className="box-1 col-xxl-4 col-xl-4 col-md-4 col-lg-4 col-12">
                    <div className="text-holder">
                      <h6>WASHROOMS</h6>
                      <p>{roomDetails?.size}</p>
                    </div>
                  </div>
                  <div className="box-2 col-xxl-2 col-xl-2 col-md-3 col-lg-2 col-12">
                    <div className="text-holder">
                      <h6>AVAILABLE COTS</h6>
                      <p>{roomDetails?.bed?.name}</p>
                    </div>
                  </div>
                  <div className="box-3 col-xxl-4 col-xl-4 col-md-4 col-lg-4 col-12">
                    <div className="text-holder">
                      <h6>LIVING CAPACITY</h6>
                      <p>{`Up to ${roomDetails?.capacity} Guest`}</p>
                    </div>
                  </div>
                  {/* <div className="box-4 col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-6">
                    <div className="text-holder">
                      <h6>View</h6>
                      <p>{roomDetails?.view}</p>
                    </div>
                  </div> */}
                </div>

                <div className="side-plat-img">
                  <div className="flower-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/RoomsDetails/RoomsDetailsInfo/plant_img.png"
                      }
                      className="plan-img"
                      alt="..."
                    />
                  </div>
                  <div className="flower-img-2">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/RoomsDetails/RoomsDetailsInfo/plant_img_2.png"
                      }
                      className="plan-img-2"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="double-room">
            <div className="row justify-content-center me-0 ms-0">
              <div className="col-xxl-11 col-lg-11 col-md-12 mx-auto ">
                <div className="row justify-content-center me-0 ms-0 p-2">
                  <div className="col-xxl-5 col-xl-5 col-md-5 col-lg-5 col-sm-12 col-12">
                    <div className="text-holder">
                      <h2>{roomDetails.name}</h2>
                    </div>
                  </div>

                  {chunkArray(facilities, 4).map((chunk, chunkIndex) => (
                    <div className="col-xxl-3 col-xl-3 col-md-3 col-lg-3 col-sm-6 col-12">
                      <ul key={chunkIndex} className="no-bullets">
                        {chunk.map((facility, index) => (
                          <li key={index}>
                            <span>
                              <FontAwesomeIcon icon="fa-solid fa-asterisk" />
                            </span>
                            <p>{facility?.facility?.name}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoomsDetailsInfo;
