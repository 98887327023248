import React from 'react'
import AboutUsBanner from "./AboutUsBanner/AboutUsBanner";
import AboutRoom from './AboutRoom/AboutRoom';
import AboutVerified from './AboutVerified/AboutVerified';
import Discover from './Discover/Discover';
import Team from './Team/Team';
import Hooks from './Hooks/Hooks';

// import Circle from './Circle/Circle';

const AboutUs = () => {
  return (
    <>
      <AboutUsBanner/>
      <AboutRoom/>
      <AboutVerified/>
      <Discover/>
      <Team/>
    <Hooks/>
      {/* <Circle/> */}
    </>
  )
}

export default AboutUs;
