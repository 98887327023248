/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect,
  useState,
  scrollPercentage,
  useContext,
} from "react";
import "../OurRoomType/OurRoomType.css";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
const OurRoomType = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [scroll, setScroll] = useState(0);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/our-room-type`);
      setData(response?.data);
      if (response?.data && response.data.length > 0) {
        setSelectedTabId(response.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;
      setScroll(scroll);
    };

    window.addEventListener("scroll", progressBarHandler);
    return () => window.removeEventListener("scroll", progressBarHandler);
  });

  return (
    <>
      <section className="Our-room-type">
        <div className="row align-items-start me-0 ms-0 ">
          {/* img tab section start */}
          <div className=" col-xl-5 col-md-6 col-sm-12 col-12 p-0">
            <div className="text-holder">
              <h2>Luxury</h2>
              <span>OUR ROOM TYPES</span>
              <h1>Rooms & Suits</h1>
            </div>

            <div className="blog-scroll">
              <div className="App">
                <div id="progressBarContainer">
                  <div
                    id="progressBar"
                    style={{
                      transform: `scale(${scroll}, 1)`,
                      opacity: `${scroll}`,
                    }}
                  />
                </div>
              </div>
              <div
                class="nav flex-column nav-pills "
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {data?.map((val, index) => (
                  <button
                    key={val.id}
                    className={`nav-link${
                      val.id === selectedTabId ? " active" : ""
                    }`}
                    type="button"
                    onClick={() => setSelectedTabId(val.id)}
                  >
                    <div class="card ">
                      <div class="row g-0">
                        <div class="col-md-4 col-4">
                          <div className=" img-holder">
                            <img
                              src={IMG_URL + val?.image}
                              className="img-fluid"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div class="col-md-8 col-8">
                          <div class="card-body">
                            <h5 class="card-title">{val?.name}</h5>
                            <p class="card-text">
                              Starting from{" "}
                              <span>₹ {parse(val?.price)}.00 /Month</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                ))}

                {/* <button
                  class="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <div class="card ">
                    <div class="row g-0 mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                      <div class="col-md-4 col-4">
                        <div className="img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/HomePage/Room_with _external_link.png"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title">Room With External Link</h5>
                          <p class="card-text">
                            Starting from <span>$50.00 / Night</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <div class="card ">
                    <div class="row g-0 mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                      <div class="col-md-4 col-4">
                        <div className="img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/HomePage/Superior_room_tab.png"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title">Superior Room</h5>
                          <p class="card-text">
                            Starting from <span>$39.00 / Night</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  <div class="card ">
                    <div class="row g-0 mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                      <div class="col-md-4 col-4">
                        <div className="img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/HomePage/Unavailable_tab.png"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title">Unavailable Room</h5>
                          <p class="card-text">
                            Starting from <span>$50.00 / Night</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <div class="card ">
                    <div class="row g-0 mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                      <div class="col-md-4 col-4">
                        <div className="img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/HomePage/Superior_room_tab.png"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title">Superior Room</h5>
                          <p class="card-text">
                            Starting from <span>$39.00 / Night</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  <div class="card ">
                    <div class="row g-0 mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                      <div class="col-md-4 col-4">
                        <div className="img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/HomePage/Unavailable_tab.png"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title">Unavailable Room</h5>
                          <p class="card-text">
                            Starting from <span>$50.00 / Night</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </button> */}
              </div>
            </div>
          </div>

          {/* img tab section end */}

          {/* img-change on tab section start */}
          <div className="change-img-content col-xxl-7 col-xl-7 col-md-6 col-sm-12 col-12">
            <div className="back_img_holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Images/HomePage/OurRoom_back_img.png"
                }
                className="img-fluid"
                alt="..."
              />
            </div>
            <div class="tab-content" id="v-pills-tabContent">
              {data?.map((val, index) => (
                <div
                  key={val.id}
                  className={`tab-pane fade${
                    val.id === selectedTabId ? " show active" : ""
                  }`}
                >
                  <div className="img-holder">
                    <img
                      src={IMG_URL + val?.image}
                      className="img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
              ))}

              {/* <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabindex="0"
              >
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/HomePage/Room_with_External_link_tab.png"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
                tabindex="0"
              >
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/HomePage/Superior_room_tab.png"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
                tabindex="0"
              >
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/HomePage/Unavailable_tab.png"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                </div>
              </div> */}
            </div>
          </div>

          {/* img-change on tab section end */}
        </div>
      </section>
    </>
  );
};
export default OurRoomType;
